import Resources from '@/services/resources';
import crypto from '@/services/crypto';
import { METHODS } from '@/services/const';

export const auth = {
    namespaced: true,
    state: () => ({
        fb864a0e5e4e278789e91cc9995ef7: {}, // auth user
    }),
    mutations: {
        setAuthState(state, data) {
            state.fb864a0e5e4e278789e91cc9995ef7 = crypto.encryptData(JSON.stringify(data), METHODS._AUTH_USER_KEY);
        },
        setUpdateAuthState(state, data) {
            state.fb864a0e5e4e278789e91cc9995ef7 = crypto.encryptData(JSON.stringify(data), METHODS._AUTH_USER_KEY);
        },
        setLogoutUser(state, data) {
            state.fb864a0e5e4e278789e91cc9995ef7 = {};
            localStorage.removeItem(METHODS._APP_NAME);
        }
    },
    actions: {
        loginAction({ state, commit }, formData) {
            return new Promise((resolve, reject) => {
                new Resources('cesp_admin/login').store(formData).then((res) => {
                    commit('setAuthState', res.data);
                    resolve(true)
                }).catch((err) => {
                    reject(err)
                })
            });
        },
        logoutAction({ state, commit }) {
            return new Promise((resolve, reject) => {
                new Resources('logout').list({}).then(res => {
                    commit('setLogoutUser', res.data.data);
                    resolve(true);
                }).catch(err => {
                    reject(err);
                })
            });
        },
    },
    getters: {
        token(state) {
            let data = state.fb864a0e5e4e278789e91cc9995ef7 ? crypto.decryptData(state.fb864a0e5e4e278789e91cc9995ef7, METHODS._AUTH_USER_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData?.access_token;
        },

        user(state) {
            let data = state.fb864a0e5e4e278789e91cc9995ef7 ? crypto.decryptData(state.fb864a0e5e4e278789e91cc9995ef7, METHODS._AUTH_USER_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData;
        },
    }
}
