import { createRouter, createWebHistory } from "vue-router";
import AdminLayout from '@/Layouts/AdminLayout';
import { store } from "./store";


const router = createRouter({
    history: createWebHistory(),
        scrollBehavior(to) {
          if (to.hash) {
            return {
              el: to.hash,
            }
          }
          return {top: 0}
        },
    
    routes: [
        {
            path: "/login",
            name: "Login",
            component: () =>
                import("./pages/Login.vue")
        },
        {
            path: "/",
            name: "LandingLayout",
            component: () => import('./Layouts/LandingLayout.vue'),
            children: [
                {
                    path: "",
                    name: "LandingPage",
                    component: () => import('./pages/Landing.vue')
                },
                {
                    path: "about",
                    name: "AboutPage",
                    component: () => import('./pages/About.vue')
                },
                {
                    path: "team",
                    name: "TeamPage",
                    component: () => import('./pages/Team.vue')
                },
                {
                    path: "policies",
                    name: "PoliciesPage",
                    component: () => import('./pages/Policies.vue')
                },
                {
                    path: "apply",
                    name: "RegisterPage",
                    component: () => import('./pages/Register.vue')
                },
                {
                    path: "payment-search",
                    name: "SearchRegistration",
                    component: () => import('./pages/SearchRegistration.vue')
                },
                {
                    path: "terms-of-service",
                    name: "TermsOfService",
                    component: () => import('./pages/TermsOfService.vue')
                },

            ]
        },
        {
            path: "/admin",
            redirect: "/admin/dashboard",
            component: AdminLayout,
            children: [
                {
                    path: "dashboard",
                    name: "Dashboard",
                    component: () =>
                        import("./pages/Dashboard.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "schools",
                    name: "Schools",
                    component: () => import("./pages/Schools.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "schools-reporting",
                    name: "SchoolsReports",
                    component: () => import("./pages/SchoolsReports.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "schools-reporting/:id",
                    name: "PreviewReport",
                    component: () => import("./pages/PreviewReport.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "excel-export",
                    name: "ExcelExport",
                    component: () => import("./pages/ExcelExport.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "claims",
                    name: "Claims",
                    component: () => import("./pages/Claims.vue"),
                    meta: { requiresAuth: true }
                },
                {
                    path: "legal-aid",
                    name: "LegalAid",
                    component: () => import("./pages/LegalAid.vue"),
                    meta: { requiresAuth: true }
                },
                {
                    path: "legal-requests",
                    name: "LegalRequests",
                    component: () => import("./pages/LegalRequests.vue"),
                    meta: { requiresAuth: true }
                },

            ]
        },
        {
            path: "/:catchAll(.*)",
            name: 'NotFound',
            children: [{
                path: "/:catchAll(.*)",
                name: "NotFound",
                component: () =>
                    import('@/pages/NotFound.vue')
            }]
        }
    ]
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const token = store.getters['auth/token'];

    if (requiresAuth && !token) {
        next({ name: 'Login' });
    } else if (to.name === 'Login' && token) {
        next({ name: 'Dashboard' });
    } else if (!requiresAuth && token) {
        next();
    } else {
        next();
    }
});

export default router;