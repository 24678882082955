<template>
  <div class="text-stone-600 relative h-full">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>