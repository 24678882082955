<template>
    <ul id="mobile_menu"
        class="list-none relative flex md:hidden items-center px-4 justify-center select-none">
        <li>
            <span @click="toggleMenu" class="material-symbols-outlined text-4xl text-blue-800 cursor-pointer menu-icon" >
                menu
            </span>
        </li>
        <div
            class="sub-menu fixed inset-0 transition duration-500" :class="showMenu ? '' : 'translate-x-[120vw]' ">
            <div @click="toggleMenu" class="background fixed inset-0 bg-black bg-opacity-50"></div>

            <ul class="list-none h-full w-2/3 flex flex-col absolute right-0 bg-blue-900 rounded-l-2xl text-white opacity-100 pt-24 px-8">
                <span @click="toggleMenu" class="material-symbols-outlined text-white absolute top-5 right-3 hover:rotate-45 transition cursor-pointer duration-100">close</span>
                <li>
                    <router-link to="dashboard" class="mob-link" >
                        <p  @click="toggleMenu" :class="nav_classes" >
                            Dashboard
                        </p>
                    </router-link>
                </li>
                <li>
                    <router-link to="schools" class="mob-link" >
                        <p  @click="toggleMenu" :class="nav_classes" >
                            All Schools
                        </p>
                    </router-link>
                </li>
                <li>
                    <router-link to="schools-reporting" class="mob-link" >
                        <p  @click="toggleMenu" :class="nav_classes" >
                            Advanced Reporting
                        </p>
                    </router-link>
                </li>
                <li>
                    <router-link to="claims" class="mob-link">
                        <p  @click="toggleMenu" class="border-b border-blue-300" :class="nav_classes">
                            Claims
                        </p>
                    </router-link>
                </li>
                <li>
                    <router-link to="legal-aid" class="mob-link" >
                        <p  @click="toggleMenu" :class="nav_classes" >
                            Legal Aid
                        </p>
                    </router-link>
                </li>

                <li>
                    <router-link to="legal-requests"  class="mob-link">
                        <p  @click="toggleMenu" class="border-b border-blue-300" :class="nav_classes">
                            Legal Requests
                        </p>
                    </router-link>
                </li>

                <li class="mt-2 ">
                    <p @click.prevent="handleLogout()"
                        class="flex items-center gap-3" :class="nav_classes">
                        <span class="material-symbols-outlined text-sm"> logout </span>
                        Logout
                    </p>
                </li>
            </ul>
        </div>
    </ul>
</template>

<script>
export default {
    name: "MobileMenu",
    props: {
        logout: Function
    },
    data() {
        return {
            showMenu: false,
            nav_classes: "text-lg hover:text-blue-500 py-3 transition duration-150 w-full"
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu
        },
        handleLogout() {
            this.toggleMenu()
            this.logout()
        }
    },

}
</script>

<style scoped>
#mobile_menu {
    -webkit-tap-highlight-color: transparent;
}

.mob-link.router-link-active {
    color: rgb(59 130 246);
}
</style>