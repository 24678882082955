let CryptoJS = require("crypto-js");

export default {
    
    // Encrypt data
    encryptData(data, key) {
         let encryptText = CryptoJS.AES.encrypt(data, key).toString();
         return encryptText;
    },

    // Decrypting data 
    decryptData(data, key) {
         let bytes  = CryptoJS.AES.decrypt(data, key);
         let originalText = bytes.toString(CryptoJS.enc.Utf8);
         return originalText;
    }
   
}