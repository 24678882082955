<template>
    <nav class="w-full h-20 shadow-md sticky top-0 z-30 select-none bg-white">
        <div class="w-full flex items-stretch h-full justify-between max-w-[1536px] mx-auto">
            <div class="md:w-fit px-4 lg:px-12 bg-white">
                <span class="flex items-center justify-center shrink-0 py-2">
                    <router-link to="/admin/dashboard"><img src="/logo.png" alt="Logo" /></router-link>
                </span>
            </div>
            <div class="hidden md:flex h-full w-full bg-blue-800 text-white justify-start xl:justify-between right-side">
                <ul class="h-full w-full shrink list-none flex justify-center xl:justify-center">
                    <li>
                        <router-link to="/admin/dashboard"
                            class="link h-full px-4 lg:px-6 text-center flex items-center justify-center cursor-pointer">
                            <p>Dashboard</p>
                        </router-link>
                    </li>
                    <li
                        class="dropdown h-full px-4 lg:px-6 text-center flex items-center justify-center gap-2 cursor-pointer relative">
                        <p>Schools</p>
                        <span class="material-symbols-outlined">arrow_drop_down</span>
                        <div
                            class="px-1 sub-menu bg-blue-800 text-white shadow-md flex flex-col whitespace-nowrap pb-2 absolute -bottom-0 translate-y-full">
    
                            <ul>
                                <li>
                                    <router-link to="/admin/schools"
                                        class="dropdown-link px-4 py-2 lg:px-6 hover:text-blue-400 transition duration-200 text-center flex items-center cursor-pointer">
                                        <p class="text-sm">All Schools</p>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/excel-export"
                                        class="dropdown-link px-4 py-2 lg:px-6 hover:text-blue-400 transition duration-200 text-center flex items-center cursor-pointer">
                                        <p class="text-sm">Excel Export</p>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/schools-reporting"
                                        class="dropdown-link px-4 py-2 lg:px-6 hover:text-blue-400 transition duration-200 text-center flex items-center cursor-pointer">
                                        <p class="text-sm">Reporting</p>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <router-link to="/admin/claims"
                            class="link h-full px-4 lg:px-6 text-center flex items-center justify-center cursor-pointer">
                            <p>Claims</p>
                        </router-link>
                    </li>
    
                    <!-- These two links are meant to show up when the logged in user is a legal aid, since they're only going to see two tabs -->
                    <li>
                        <router-link v-if="false" to="legal-aid"
                            class="link h-full px-4 lg:px-6 text-center flex items-center justify-center cursor-pointer">
                            <p>Legal Aid</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link v-if="false" to="legal-requests"
                            class="link h-full px-4 lg:px-6 text-center flex items-center justify-center cursor-pointer">
                            <p>Legal Requests</p>
                        </router-link>
                    </li>
                    <!-- ================ -->
    
                    <li
                        class="dropdown h-full px-4 lg:px-6 text-center flex items-center justify-center gap-2 cursor-pointer relative">
                        <p>Legal</p>
                        <span class="material-symbols-outlined">arrow_drop_down</span>
                        <div
                            class="px-1 sub-menu bg-blue-800 text-white shadow-md flex flex-col whitespace-nowrap pb-2 absolute -bottom-0 translate-y-full">
    
                            <ul>
                                <li>
                                    <router-link to="/admin/legal-aid"
                                        class="dropdown-link px-4 py-2 lg:px-6 hover:text-blue-400 transition duration-200 text-center flex items-center cursor-pointer">
                                        <p class="text-sm">Legal Aid</p>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/legal-requests"
                                        class="dropdown-link px-4 py-2 lg:px-6 hover:text-blue-400 transition duration-200 text-center flex items-center cursor-pointer">
                                        <p class="text-sm">Legal Requests</p>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
    
                <div class="px-4 text-center flex items-center justify-center cursor-pointer">
                    <button @click.prevent="logout()"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 lg:px-4 rounded">
                        Logout
                    </button>
                </div>
            </div>
            <mobile-menu :logout="logout" />
        </div>
    </nav>
</template>

<script>
import { mapActions } from "vuex";
import MobileMenu from "./MobileMenu.vue";
export default {
    name: "HeaderComponent",
    methods: {
        ...mapActions({
            logoutUser: "auth/logoutAction",
        }),
        logout() {
            let text = "USER LOGOUT!\nAre you sure you want to logout?";
            if (confirm(text) !== true) {
                return null;
            }
            this.logoutUser()
                .then(() => {
                    this.$router.push({ name: "Login" });
                })
                .catch(() => {
                    alert("Could Not Log out, sorry.")
                });
        },
    },
    components: { MobileMenu, }
};
</script>

<style scoped>
.right-side::before {
    content: "";
    width: 100px;
    transform: translateX(-1px);
    background-color: white;
    -webkit-clip-path: polygon(100% 0, 0% 100%, 0 0);
    clip-path: polygon(100% 0, 0% 100%, 0 0);
}

@media screen and (max-width: 1024px) {
    .right-side::before {
        width: 40px;
    }
    
}

img {
    height: auto;
    width: auto;
    max-height: 72px;
    max-width: 250px;
}

.link.router-link-active {
    position: relative;
    background-color: rgb(16, 16, 82);
}
.link {
    transition: all 0.3s;
}
.link:hover {
    background-color: rgba(60, 60, 105, 0.3);
    box-sizing: border-box;
}

.link.router-link-active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(52 211 153);
}

.dropdown-link.router-link-active {
    color: rgb(96 165 250);
}

.dropdown .sub-menu {
    display: none;
}

.dropdown:hover .sub-menu {
    display: block;
    position: absolute;
}

</style>
