export const METHODS = {
    _ListMethod: "LIST",
    _StoreMethod: "STORE",
    _PutMethod: "PUT",
    _DeleteMethod: "DELETE",
    _APP_NAME: "ca906389-5169-47ec-b40d-c5659743fb021",
    _TOKEN_KEY: "532b3062-4e63-4a9c-a68c-609c00c30722",
    _AUTH_USER_KEY: "8976d468-6c61-4cd7-ac3e-a92fc391c0e6",
    _DASHBOARD_KEY: "b1860f5f-9163-43ff-baf8-f32fbf8ed34b",
    _SCHOOL_KEY: "ceda8bb0-6049-4792-a038-069a9d2d7195",
    _CLAIMS_KEY: "bf5136d2-9bc5-46bb-b085-666c9e34e195",
    _REPORTS_KEY: "cede0d0c-e2f7-4c40-9515-f95d66cc84f6",
    _EXCEL_EXPORT_KEY: "da9547a3-5a8f-4f45-8747-38c705f4fe49"
}
