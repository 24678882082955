import Resource from "@/services/resources"

export const landing = {
    namespaced: true,
    state:  () => ({
        departments: [],
        associations: [],
        regions: [],
    }),
    mutations: {
        setRegionsState(state, data) {
            state.regions = data
        },
        setAssociationsState(state, data) {
            state.associations = data
        },
        setDepartmentsState(state, data) {
            state.departments = data
        },
    },
    actions: {
        fetchRegions({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/regions").list({})
                .then(({data}) => {
                    commit('setRegionsState', data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        fetchAssociations({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/associations").list({})
                .then(({data}) => {
                    commit('setAssociationsState', data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        fetchDepartments({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/departments").list({})
                .then(({data}) => {
                    commit('setDepartmentsState', data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        registerSchool({ commit }, schoolData) {
            return new Promise((resolve, reject) => {
                new Resource("admin/register_school").store(schoolData)
                .then(res => {
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        } 
    },
    getters: {
        getRegions(state) {
            return state.regions
        },
        getAssociations(state) {
            return state.associations
        },
        getDepartments(state) {
            return state.departments
        },
    }
}