import VuexPersistence from "vuex-persist";
import { createStore } from "vuex";

import { METHODS } from "@/services/const";
import { page } from "./modules/page.store";
import { legal } from "./modules/legal.store";
import { auth } from "./modules/auth.store";
import { landing } from "./modules/landing.store";
import { reports } from "./modules/reports.store";

const vuexLocal = new VuexPersistence({
    key: METHODS._APP_NAME,
    storage: window.localStorage
});

export const store = new createStore({
    modules: { page, auth, legal, landing, reports },
    plugins: [vuexLocal.plugin],
});