import { createApp } from 'vue';
import App from './App.vue';
import vSelect from 'vue-select'


import { store } from './store';
import Sweetalert from 'vue-sweetalert2';
import Loading from 'vue-loading-overlay';
import router from './route';
import printData from 'print-js';

import './index.css';
import 'vue-loading-overlay/dist/css/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';

const app = createApp(App);

window.printData = printData;

app.use(router);
app.use(store);
app.use(Sweetalert);
app.component("loading", Loading);
app.component("v-select", vSelect);


app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

app.mount('#app');
