import Resource from "@/services/resources";
import { METHODS } from "@/services/const";
import crypto from "@/services/crypto";

export const page = {
    namespaced: true,
    state: () => ({
        schoolData: [],
        allDashboardData: [],
        claims: [],
    }),
    mutations: {
        setChartDataState(state, data) {
            state.allDashboardData = crypto.encryptData(JSON.stringify(data), METHODS._DASHBOARD_KEY);
        },

        setSchoolDataState(state, data) {
            state.schoolData = crypto.encryptData(JSON.stringify(data), METHODS._SCHOOL_KEY);
        },

        setClaimsDataState(state, { data, status }) {
            let oldData = state.claims ? crypto.decryptData(state.claims, METHODS._CLAIMS_KEY) : "";
            let parseData = oldData ? JSON.parse(oldData) : [];

            if(status === METHODS._ListMethod) {
                parseData = data;
            } else if(status === METHODS._PutMethod) {
                
                parseData = parseData.map(item => {
                     if(item.id === data.id) {
                        Object.keys(item).forEach(key => {
                            if(key === "status") {
                               item[key] = data.status;
                            }
                        });
                        return item;
                     }
                     return item;
                });
            }

            state.claims = crypto.encryptData(JSON.stringify(parseData), METHODS._CLAIMS_KEY);
        },
    },
    actions: {
        getChartDataAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/dashboard").list({})
                    .then(res => {
                        commit('setChartDataState', res.data)
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getSchoolDataAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/schools").list({})
                    .then(res => {
                        commit('setSchoolDataState', res.data)
                        resolve(true)
                    })
                    .catch(error => reject(error))
            })
        },
        getClaimsDataAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/insurance_claims").list({})
                    .then(res => {
                        commit('setClaimsDataState', { data: res.data, status: METHODS._ListMethod })
                        resolve(true)
                    })
                    .catch(error => reject(error))
            })
        },
        setClaimStatusAction({ commit }, data) {
            return new Promise((resolve, reject) => {
                new Resource(`admin/insurance_claims/${data.id}/status`).updateWithIDURL({ "status": data.status })
                    .then(res => {
                        commit("setClaimsDataState", { data: res.data, status: METHODS._PutMethod });
                        resolve(true);
                    })
                    .catch(error => reject(error))
            })
        },
        setClaimReviewAction({ commit }, data) {
            return new Promise((resolve, reject) => {
                new Resource(`admin/insurance_claims/${data.id}/review`).updateWithIDURL({ "review_message": data.review_message})
                    .then(res => {
                        commit("setClaimsDataState", { data: res.data, status: METHODS._PutMethod });
                        resolve(true);
                    })
                    .catch(error => reject(error))
            })
        },
    },
    getters: {
        getRegionData(state) {
            let data = state.allDashboardData ? crypto.decryptData(state.allDashboardData, METHODS._DASHBOARD_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData?.by_region;
        },
        getGradeData(state) {
            let data = state.allDashboardData ? crypto.decryptData(state.allDashboardData, METHODS._DASHBOARD_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData?.by_grade;
        },
        getStatistics(state) {
            let data = state.allDashboardData ? crypto.decryptData(state.allDashboardData, METHODS._DASHBOARD_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData?.statistics;
        },
        getEnrollmentData(state) {
            let data = state.allDashboardData ? crypto.decryptData(state.allDashboardData, METHODS._DASHBOARD_KEY) : "";
            let parseData = data ? JSON.parse(data) : "";
            return parseData?.by_enrollment;
        },
        getSchoolData(state) {
            let data = state.schoolData ? crypto.decryptData(state.schoolData, METHODS._SCHOOL_KEY) : "";
            return data ? JSON.parse(data) : ""
        },
        getClaimsData(state) {
            let data = state.claims ? crypto.decryptData(state.claims, METHODS._CLAIMS_KEY) : "";
            return data ? JSON.parse(data) : ""
        },
    }
};