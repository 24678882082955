import Resource from "@/services/resources";

export const legal = {
    namespaced: true,
    state: () => ({
        legalDocuments: [],
        legalRequests: []
    }),
    mutations: {
        setLegalDocumentsState(state, data) {
            state.legalDocuments = data
        },
        appendLegalDocumentState(state, data) {
            let reversedDocuments = [...data].reverse() //Reversing so the documents can be in the order in which they were added
            let newDocs = [...reversedDocuments, ...state.legalDocuments]
            state.legalDocuments = newDocs
        },
        replaceLegalDocumentState(state, data) {
            let newDocs = state.legalDocuments.map((document) => {
                if (document.id === data.id) { document = data }
                return document
            })
            state.legalDocuments = newDocs
        },
        removeLegalDocumentState(state, id) {
            let newDocs = state.legalDocuments.filter((document) => document.id !== id)
            state.legalDocuments = newDocs
        },
        setLegalRequestsState(state, data) {
            state.legalRequests = data
        }
    },
    actions: {
        fetchLegalDocumentsAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/legal_aids").list({})
                    .then(res => {
                        commit('setLegalDocumentsState', res.data)
                    })
                    .catch((error) => reject(error))
            })
        },
        setLegalDocumentsAction({ commit }, documents) {
            return new Promise((resolve, reject) => {
                new Resource("admin/legal_aids").store(documents)
                    .then(res => {
                        commit('appendLegalDocumentState', res.data)
                        resolve(true)
                    })
                    .catch((error) => reject(error))
            })
        },
        editLegalDocumentAction({ commit }, { document, id, withFile }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/legal_aids").update(document, id, withFile)
                    .then(res => {
                        commit('replaceLegalDocumentState', res.data)
                        resolve(true)
                    })
                    .catch((error) => reject(error))
            })
        },
        deleteLegalDocumentAction({ commit }, id) {
            return new Promise((resolve, reject) => {
                new Resource("admin/legal_aids").destroy(id)
                    .then(() => {
                        commit('removeLegalDocumentState', id)
                        resolve(true)
                    })
                    .catch((error) => reject(error))
            })
        },
        fetchLegalRequestsAction({ commit }) {
            return new Promise((resolve, reject) => {
                new Resource("admin/school_aid_request").list({})
                    .then(res => {
                        console.log(res.data)
                        commit('setLegalRequestsState', res.data)
                        resolve(true)
                    })
                    .catch((error) => reject(error))
            })
        }
    },
    getters: {
        getLegalDocuments(state) {
            return state.legalDocuments
        },
        getLegalRequests(state) {
            return state.legalRequests
        },
    },
}